
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import UserContext from './userContext';
import { CircularProgress, Box } from '@mui/material';

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null); // Add session state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session?.user && session?.access_token) {
        // Decode the JWT to get user_role
        const decodedToken = jwtDecode(session.access_token);
        const userRole = decodedToken.user_role || 'user';
        setUser({ ...session.user, user_role: userRole });
        setSession(session); // Set session state
      } else {
        setUser(null);
        setSession(null); // Clear session state
      }

      setLoading(false);
    };

    getSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session?.user && session?.access_token) {
        const decodedToken = jwtDecode(session.access_token);
        const userRole = decodedToken.user_role || 'user';
        setUser({ ...session.user, user_role: userRole });
        setSession(session); // Set session state
      } else {
        setUser(null);
        setSession(null); // Clear session state
      }
      setLoading(false);
    });

    return () => subscription?.unsubscribe();
  }, []);

  if (loading) {
    // Display a loading indicator while checking authentication
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <UserContext.Provider value={{ user, session }}>
      {children}
    </UserContext.Provider>
  );
};

export default AuthProvider;