// src/RequireAuth.js

import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserContext from './contexts/userContext';

const RequireAuth = ({ requiredRole, children }) => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (!user) {
    const redirectTo = encodeURIComponent(location.pathname + location.search + location.hash);
    return <Navigate to={`/login?redirectTo=${redirectTo}`} replace />;
  }

  if (requiredRole && user.user_role !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RequireAuth;
