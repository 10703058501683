import React, { useState, useRef, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material/styles";
import supabase from "./supabaseClient";
import UserContext from "./contexts/userContext";

function Header({ toggleTheme, themeMode }) {
  const {user} = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const location = useLocation();

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    window.location.reload();
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const avatarUrl = user?.user_metadata?.avatar_url;

  // Function to handle logo click and reload the page
  const handleLogoClick = () => {
    window.location.href = "/";
  };

  // const allowedEmails = ['tim.silber@me.com', 'testuser@reciply.xyz', 'testuser@example.com'];

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      {/* Wrap Toolbar content in a Box with maxWidth and mx: 'auto' */}
      <Box
        sx={{
          // maxWidth: {
          //   xs: '100%',
          //   sm: 800,
          //   md: 1100,
          // },
          mx: "auto",
          width: "100%",
        }}
      >
        <Toolbar
          sx={{
            padding: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Candal",
              cursor: "pointer",
              fontSize: "40px",
              lineHeight: 1,
              userSelect: "none",
            }}
            onClick={handleLogoClick}
          >
            {"{r}"}
          </Typography>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          >
            <IconButton sx={{ ml: 1 }} onClick={toggleTheme} color="inherit">
              {themeMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {user ? (
              <div>
                <IconButton
                  ref={anchorRef}
                  onClick={handleMenuOpen}
                  color="inherit"
                  // edge="end"
                  sx={{
                    borderRadius: "50%",
                    // ml: 1,
                  }}
                >
                  {avatarUrl ? (
                    <Avatar
                      src={avatarUrl}
                      sx={{ width: "35px", height: "35px" }}
                    />
                  ) : (
                    <AccountCircleIcon sx={{ width: "35px", height: "35px" }} />
                  )}
                </IconButton>
                <Menu
                  anchorEl={anchorRef.current}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  sx={{
                    "& .MuiPaper-root": {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                      border: `1px solid ${theme.palette.divider}`,
                    },
                  }}
                >
                  <MenuItem
                    disabled
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    {user.email}
                  </MenuItem>
                  <Link
                    to="/lists"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem
                      onClick={handleMenuClose}
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      lists
                    </MenuItem>
                  </Link>
                  <Link
                    to="/books"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem
                      onClick={handleMenuClose}
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      books
                    </MenuItem>
                  </Link>
                  <Link
                    to="/meal-plan"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem
                      onClick={handleMenuClose}
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      meal planner
                    </MenuItem>
                  </Link>
                  <Link
                      to="/upload"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <MenuItem
                        onClick={handleMenuClose}
                        sx={{ fontFamily: theme.typography.fontFamily }}
                      >
                        upload
                      </MenuItem>
                    </Link>
                  <Link
                    to="/settings"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem
                      onClick={handleMenuClose}
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      settings
                    </MenuItem>
                  </Link>                    

                  <MenuItem
                    onClick={() => {
                      handleSignOut();
                      handleMenuClose();
                    }}
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    sign out
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Link
                to={`/login?redirectTo=${encodeURIComponent(
                  location.pathname + location.search + location.hash
                )}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button color="inherit">login</Button>
              </Link>
            )}
          </div>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default Header;
